import "../pages/home.css";
import { Card, Button, Image, Nav, Container, Navbar, ListGroup, Row }  from 'react-bootstrap';
import { IoLogoMedium } from "react-icons/io5";
import { FaInstagram, FaGithub, FaAppStoreIos } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";

function Home() {
    return (
      <>
        <Container>
          <Navbar>
            <Container className="navbar-inner">
              <Navbar.Brand>
                <Image src="./Tashan.png" style={{ weight: "80px", height: "80px" }}/>
              </Navbar.Brand>
              <Nav>
                <Nav.Link href="https://www.buymeacoffee.com/onurtashan" target="_blank">
                  <Image src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{ height: "3rem", width: "10rem"}}/>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <Container>
          <Card aria-expanded className="navbar-inline shadow p-3 m-3" style={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }}>
            <Card.Header>
              <Container>
                <Button className="mr-3" variant="" target="_blank" href="https://apps.apple.com/us/app/battle-of-moon/id1403159509" style={{ fontWeight: "bold", borderRadius: "30px", backgroundColor: "rgba(245, 245, 245, 0.4)" }}>
                  <Image src="./BattleOfMoon.png" style={{ height: "85px", borderRadius: "10px" }}/>
                </Button>
                <Button className="mr-3" variant="" target="_blank" href="https://apps.apple.com/us/app/snake-xl/id1401152515" style={{ fontWeight: "bold", borderRadius: "30px", backgroundColor: "rgba(245, 245, 245, 0.4)" }}>
                  <Image src="./SnakeXL.png" style={{ height: "85px", borderRadius: "10px" }}/>
                </Button>
                <Button className="mr-3" variant="" target="_blank" href="https://apps.apple.com/us/app/magicprintai/id6479582657" style={{ fontWeight: "bold", borderRadius: "30px", backgroundColor: "rgba(245, 245, 245, 0.4)" }}>
                  <Image src="./MagicPrintAI.png" style={{ height: "85px", borderRadius: "10px" }}/>
                </Button>
              </Container>
            </Card.Header>
            <Card.Body className="m-1">
              <Container>
                <ListGroup style={{display:'flex', justifyContent:'left'}}>
                <ListGroup.Item variant="dark" style={{ fontWeight: "bold" }}>
                      Medium Articles
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/@onurtashan/manage-dbt-jobs-on-amazon-managed-airflow-via-github-actions-d26414b1eec0" target="_blank">Manage dbt Jobs on Amazon Managed Airflow via GitHub Actions</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/how-to-load-data-from-google-bigquery-to-vertica-2fbdc149e538" target="_blank">How to Load Data from Google BigQuery to Vertica?</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/how-to-export-data-from-bigquery-to-excel-a65b81b20135" target="_blank">How to Export Data from BigQuery to Excel?</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/how-to-load-data-from-elasticsearch-to-bigquery-64d98a1c6a82" target="_blank">How to Load Data from ElasticSearch to BigQuery?</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/how-to-load-google-shared-drive-files-to-google-bigquery-fac22c11ef9e" target="_blank">How to load Google Shared Drive Files to Google BigQuery?</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/slack-notification-for-bitbucket-forgotten-pull-requests-51e35c039b55" target="_blank">Slack Notification for Bitbucket Forgotten Pull Requests</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/google-bigquery-export-table-metadata-to-git-repository-cf8fbd8bf729" target="_blank">Google BigQuery — Export Table Metadata to Git Repository</a></span>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark" style={{display:'flex', justifyContent:'left'}}>
                    <IoLogoMedium className="mr-1" style={{ width: '2rem', height: '2rem'}}/>
                      <span style={{ wordBreak: "break-word", fontStyle: "italic" }}><a style= {{ color: "black" }} href="https://medium.com/trendyol-tech/postgresql-to-google-bigquery-with-python-delta-extraction-cd57b96abb23" target="_blank">PostgreSQL to Google BigQuery with Python — Delta Extraction</a></span>
                  </ListGroup.Item>
                </ListGroup>
              </Container>
            </Card.Body>
            <Card.Footer className="justify-content-between">
              <Container>
                <Button className="mr-2" variant="dark" target="_blank" href="https://apps.apple.com/us/developer/onur-tashan/id1396541715" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <FaAppStoreIos src="" alt="Apple AppStore" style={{ height: "2rem", width: "2rem"}}/>
                </Button>
                <Button className="mr-2" variant="dark" target="_blank" href="https://instagram.com/onurtashan" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <FaInstagram style={{ width: '2rem', height: '2rem'}}/>
                </Button>
                <Button className="mr-2" variant="dark" target="_blank" href="https://x.com/onurtashan" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <FaSquareXTwitter style={{ width: '2rem', height: '2rem'}}/>
                </Button>
                <Button className="mr-2" variant="dark" target="_blank" href="https://linkedin.com/in/onurtashan" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <BsLinkedin  style={{ width: '2rem', height: '2rem'}}/>
                </Button>
                <Button className="mr-2" variant="dark" target="_blank" href="https://github.com/onurtashan" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <FaGithub style={{ width: '2rem', height: '2rem'}}/>
                </Button>
                <Button className="mr-2" variant="dark" target="_blank" href="https://medium.com/@onurtashan" style={{ fontWeight: "bold", borderRadius: "30px" }}>
                  <IoLogoMedium style={{ width: '2rem', height: '2rem'}}/>
                </Button>
              </Container>
            </Card.Footer>
          </Card>
          </Container>
        </Container>
      </>
    );
}

export default Home;